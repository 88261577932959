import styles from './Footer.module.scss'
import Link from 'next/link'
import { getSocName } from '../../utils/getSocName'
import { Socnet } from '../../interfaces'
import Nav from './Nav'
import YandexSvg from './icons/yandex-cloud.svg'
import OpsguruSvg from './icons/opsguru.svg'
import SkamSvg from './icons/skam.svg'
import FooterLogo from './icons/footer-logo.svg'
import { MISTAKE_BOT_LINK, NAV_ITEMS_MOBILE } from '../../lib/constants/links'
import { useRouter } from 'next/router'
import cn from 'classnames'
import ThemeSwitcher from './ThemeSwitcher'
import { useEffect, useState } from 'react'
import { ThemeEnum } from '../../types'
import useLocalStorage from '../../hooks/useLocalStorage'

const NAV_FOOTER_2 = [
  // {
  //   href: '/privacy',
  //   name: 'Приватность',
  //   id: 'privacy',
  // },
  {
    href: '/about',
    name: 'Об издании',
    id: 'about',
  },
  {
    href: '/pdf/kod-mediakit.pdf',
    name: 'Реклама',
    id: 'advertising',
  },
]

const NAV_FOOTER_3 = [
  {
    href: 'https://kod.ru/o_otziv',
    name: 'О сервисе',
  },
  {
    href: 'https://kod.ru/faq_otziv',
    name: 'FAQ',
  },
]

const Footer = ({ socials }) => {
  const [theme, setTheme] = useState<ThemeEnum>(ThemeEnum.system)
  const [storageTheme, setStorageTheme] = useLocalStorage<ThemeEnum>(
    'theme',
    ThemeEnum.system
  )

  const year = new Date().getFullYear()
  const router = useRouter()

  const captchaPaths = ['/new-review', '/catalog', '/company']

  const isShowCaptcha = captchaPaths.some((path) =>
    router.asPath.startsWith(path)
  )

  const renderSocialLinks = (socials) => (
    <ul className={styles.soc}>
      {socials.map((s) => (
        <li className={styles.socItem} key={s.code}>
          <Link
            className={styles.socLink}
            href={s.href}
            target="_blank"
            title={getSocName(s.code as Socnet)}
            aria-label={`Ссылка на ${s.label}`}
          >
            <span className={styles.iconColor}>{s.icon}</span>
          </Link>
        </li>
      ))}
    </ul>
  )

  const renderNavFooterLinks = () => (
    <nav className={styles.navTwo}>
      {NAV_FOOTER_2.map((i) => {
        const isAdvert = i.id === 'advertising'

        return (
          <Link
            key={i.href}
            href={i.href}
            download={i.id === 'advertising'}
            target={isAdvert ? '_blank' : '_self'}
            className={cn(isAdvert && styles.downloadLink)}
          >
            {i.name}
            {isAdvert && <span>pdf 2,7mb</span>}
          </Link>
        )
      })}
    </nav>
  )

  const renderNavReportLinks = () => (
    <nav className={styles.navThree}>
      {NAV_FOOTER_3.map((i) => (
        <Link target="_blank" key={i.href} href={i.href}>
          {i.name}
        </Link>
      ))}
    </nav>
  )

  function onThemeChange(value: ThemeEnum) {
    setTheme(value)
    setStorageTheme(value)

    let theme =
      (value === ThemeEnum.system &&
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches) ||
      value === ThemeEnum.dark
        ? 'dark'
        : 'light'

    if (theme === 'light' || !theme) {
      ;(window as any).theme = 'light'
      document.body.classList.remove('dark')
      document.body.classList.add('light')
      document.documentElement.style.colorScheme = 'light'
    } else {
      ;(window as any).theme = 'dark'
      document.body.classList.remove('light')
      document.body.classList.add('dark')
      document.documentElement.style.colorScheme = 'dark'
    }
  }

  useEffect(() => {
    setTheme(storageTheme)
  }, [])

  return (
    <div className={styles.content}>
      <div className={styles.container}>
        <div className={styles.withLogo}>
          <Link
            href="/"
            className={styles.footerLogo}
            aria-label="Ссылка на главную"
          >
            <FooterLogo />
          </Link>
          <div className={styles.social}>
            <p className={styles.text}>[ Код Дурова ] в соцсетях</p>
            {renderSocialLinks(socials)}
          </div>
          <p className={styles.copyright}>
            © {year}, Код Дурова. Все права защищены.
          </p>
        </div>

        <div className={styles.navigations}>
          <div className={styles.links}>
            <div className={styles.columnOne}>
              <Nav footer className={styles.nav} navItems={NAV_ITEMS_MOBILE} />
              <div className={styles.yandex}>
                <p>Код храним на серверах</p>
                <Link
                  href="https://cloud.yandex.ru/"
                  target="_blank"
                  aria-label="Ссылка на yandex cloud"
                >
                  <YandexSvg />
                </Link>
              </div>
            </div>

            <div className={styles.columnTwo}>
              {renderNavFooterLinks()}
              <div className={styles.opsguru}>
                <p>Техподдержка</p>
                <OpsguruSvg />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.report}>
          <div className={styles.top}>
            <Link
              href={MISTAKE_BOT_LINK}
              className={styles.button}
              target="_blank"
            >
              Сообщить об ошибке
            </Link>
            <ThemeSwitcher
              onChange={onThemeChange}
              defaultTheme={theme}
              className={styles.themeSwitcher}
            />
            {isShowCaptcha && (
              <>
                <p className={styles.captcha}>
                  Сайт защищен reCAPTCHA. Используя его, вы соглашаетесь с{' '}
                  <Link
                    href={`https://policies.google.com/privacy`}
                    target="_blank"
                    className={styles.link}
                  >
                    политикой конфиденциальности
                  </Link>{' '}
                  и{' '}
                  <Link
                    href={`https://policies.google.com/terms`}
                    target="_blank"
                    className={styles.link}
                  >
                    условиями использования
                  </Link>{' '}
                  Google
                </p>
                {renderNavReportLinks()}
              </>
            )}
          </div>
          <Link
            href="https://skam.team"
            target="_blank"
            className={styles.skamSvg}
            aria-label="Ссылка на skam"
          >
            <SkamSvg />
          </Link>
        </div>
        <div className={styles.mobile}>
          {renderSocialLinks(socials)}
          <Link
            href={MISTAKE_BOT_LINK}
            className={styles.button}
            target="_blank"
          >
            Сообщить об ошибке
          </Link>
          <ThemeSwitcher
            onChange={onThemeChange}
            defaultTheme={theme}
            className={styles.themeSwitcher}
          />
          <div className={styles.advertising}>
            <div className={styles.opsguru}>
              <p>Техподдержка</p>
              <OpsguruSvg />
            </div>
            <p className={styles.copyright}>
              <span>© {year}</span>
              <span>Код Дурова</span>
              <span>Все права защищены.</span>
            </p>
          </div>
          <div className={styles.advertisingTwo}>
            <div className={cn(styles.yandex, styles['yandex--mobile'])}>
              <p>Код храним на серверах</p>
              <Link
                href="https://cloud.yandex.ru/"
                target="_blank"
                aria-label="Ссылка на yandex cloud"
              >
                <YandexSvg />
              </Link>
            </div>
            <Link
              href="https://skam.team"
              target="_blank"
              className={styles.skamSvg}
              aria-label="Ссыдка на skam"
            >
              <SkamSvg />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
